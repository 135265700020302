import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Svg from '../../components/Svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    cursor: pointer;
    fill: ${(props) => props.theme.findStore?.iconsColor};
  }
`;

const Input = styled.input`
  flex: 1;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 10px;
  margin-right: 10px;
`;

type PropTypes = {
  onPlacesChanged?: (places: google.maps.places.PlaceResult[]) => void;
  onGetLocation?: (lat: number, lng: number) => void;
};

function GooglePlaceSearch({ onPlacesChanged, onGetLocation }: PropTypes) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      const search = new google.maps.places.SearchBox(ref.current);
      search.addListener('places_changed', () => {
        onPlacesChanged?.(
          search.getPlaces() as google.maps.places.PlaceResult[],
        );
        ref.current?.blur();
      });
    }
  }, [onPlacesChanged]);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      onGetLocation?.(position.coords.latitude, position.coords.longitude);
      // Todo: Implement the reverse position to show the address
      // const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

      // const geocoder = new google.maps.Geocoder();
      // geocoder.geocode({ location }, (results, status) => {
      //   console.log(status);
      //   console.log(results);
      // });
    });
  };

  return (
    <Wrapper>
      <Input type="text" ref={ref} placeholder="Enter your location" />
      <Svg.LocationPicker
        width="20px"
        height={'20px'}
        onClick={() => getLocation()}
      />
    </Wrapper>
  );
}

export default GooglePlaceSearch;
