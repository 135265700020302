import Script from 'next/script';

const BazaarVoiceScript = () => {
  const baseUrl = `https://apps.bazaarvoice.com/deployments`;

  const instance = process.env.NEXT_PUBLIC_BV_INSTANCE;
  const zone = process.env.NEXT_PUBLIC_BV_DEPLOYMENT_ZONE;
  const env = process.env.NEXT_PUBLIC_BV_ENVIRONMENT;
  const locale = process.env.NEXT_PUBLIC_BV_LOCALE;

  if (instance && zone && env && locale) {
    return (
      <Script src={`${baseUrl}/${instance}/${zone}/${env}/${locale}/bv.js`} />
    );
  }
  return null;
};

export default BazaarVoiceScript;
