import React, { PropsWithChildren } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorPage from 'next/error';
import { NextPageContext } from 'next';

export const bugsnagEnabled = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY !== '';
let started = false;
if (!started && bugsnagEnabled) {
  started = true;
  Bugsnag.start({
    // Don't send hydration errors to Bugsnag
    onError: (event) =>
      new Promise((resolve) => {
        if (
          event.errors.some(
            (error) =>
              error.errorMessage.startsWith('Minified React error #418') ||
              error.errorMessage.startsWith('Minified React error #423')
          )
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      }),
    enabledReleaseStages: ['test', 'qa', 'preprod', 'production'],
    apiKey: `${process.env.NEXT_PUBLIC_BUGSNAG_API_KEY}`,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.NODE_ENV,
  });
}

const Error = class Page extends React.Component<{
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}> {
  static async getInitialProps(ctx: NextPageContext) {
    if (ctx.err && bugsnagEnabled) Bugsnag.notify(ctx.err);
    return ErrorPage.getInitialProps(ctx);
  }
  render() {
    return <ErrorPage statusCode={500} />;
  }
};

const ErrorBoundary = ({ children }: PropsWithChildren) => {
  if (!bugsnagEnabled) {
    return <>{children}</>;
  }

  const BugsnagBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary();
  return (
    <BugsnagBoundary FallbackComponent={Error}>{children}</BugsnagBoundary>
  );
};

export default ErrorBoundary;
