import { H1, H2 } from '@thisisdevelopment/akzonobel-core';
import { ContainerInner } from 'elements';
import { FC, ReactNode } from 'react';
import theme from 'theme';
import { PageStyled, Image, Spacer, DesktopHeader } from './styled';

type PropType = {
  title?: string;
  headline?: string | ReactNode;
  children?: ReactNode;
  bgColor?: string;
  color?: string;
  prefix?: string;
  prefixColor?: string;
  image?: { url: string; alt: string };
};

const Page: FC<PropType> = ({
  title,
  headline,
  children,
  bgColor = theme.colors.white,
  color = theme.colors.brandGold,
  prefix,
  prefixColor = theme.colors.brandGold,
  image,
  ...props
}: PropType) => {
  return (
    <PageStyled bgColor={bgColor} {...props}>
      {image && (
        <>
          <Spacer />
          <Image
            alt={image.alt}
            priority={true}
            src={image.url}
            width={1600}
            height={819}
          />
        </>
      )}
      <DesktopHeader
        $color={color}
        $prefixColor={prefixColor}
        $hasImage={!!image}
      >
        <ContainerInner>
          {prefix && <H2>{prefix}</H2>}
          {title && <H1>{title}</H1>}
          {headline && <>{headline}</>}
        </ContainerInner>
      </DesktopHeader>
      {children}
    </PageStyled>
  );
};

export default Page;
